<template>
  <section class="section section-standalone" id="kerjasama">
    <div class="container-fluid">
      <div class="my-3">
        <div class="c-dashboard-title d-flex justify-content-between pl-2">
          <h1 class="primary-font text-size-title text-uppercase mb-0">
            Kerjasama
          </h1>
          <div class="c-dashboard-title-option d-flex align-items-center">
            <select
              class="form-control form-control-sm mr-2 form-control-custom"
              v-model="tahun"
              v-on:change="tahunChange()"
            >
              <option :value="item" v-for="item in array_tahun" :key="item">
                {{ item }}
              </option>
            </select>
          </div>
        </div>
        <div class="grid-section">
          <div class="grid-section-item">
            <div
              class="bg-white rounded border border-radius-style card-dashboard h-100"
            >
              <div class="card-dashboard-title px-3 py-2">
                <h4 class="mb-0">
                  Status
                </h4>
              </div>
              <div class="card-dashboard-content p-3">
                <div class="row align-items-center">
                  <div class="col-md-6">
                    <div class="chart-doughnut--wrapper">
                      <canvas
                        id="kerjasama-chart"
                        width="400"
                        height="200"
                        :chart="chartStatus"
                      ></canvas>
                      <div class="chart-doughnut--text">
                        <h3
                          class="mb-1 secondary-font text-size-large font-weight-bold"
                        >
                          {{ total_project }}
                        </h3>
                        <h6
                          class="mb-0 secondary-font text-uppercase text-size-small font-weight-normal"
                        >
                          Total Project
                        </h6>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="card p-2 mb-2 border-0 bg-gray-new">
                      <div
                        class="d-flex align-items-center justify-content-between"
                      >
                        <div
                          class="d-flex flex-row align-items-center c-list-item--tacao"
                        >
                          <img
                            src="@/assets/img/icon/icon-rate-gray.svg"
                            alt=""
                            class="img-responsive"
                          />
                          <h4
                            class="text-gray-new font-weight-bold text-size-large mb-0 ml-3 d-flex align-items-center justify-content-end"
                          >
                            {{ project_pending }}
                          </h4>
                        </div>
                        <div class="flex-row text-right">
                          <p
                            class="mb-0 text-size-mini text-uppercase font-weight-normal"
                          >
                            PENDING
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="card p-2 mb-2 border-0 bg-red-new">
                      <div
                        class="d-flex align-items-center justify-content-between"
                      >
                        <div
                          class="d-flex flex-row align-items-center c-list-item--tacao"
                        >
                          <img
                            src="@/assets/img/icon/icon-rate-red.svg"
                            alt=""
                            class="img-responsive"
                          />

                          <h4
                            class="text-red-new font-weight-bold text-size-large mb-0 ml-3 d-flex align-items-center justify-content-end"
                          >
                            {{ project_terminate }}
                          </h4>
                        </div>
                        <div class="flex-row text-right">
                          <p
                            class="mb-0 text-size-mini text-uppercase font-weight-normal"
                          >
                            Terminated
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="card p-2 mb-2 border-0 bg-yellow-new">
                      <div
                        class="d-flex align-items-center justify-content-between"
                      >
                        <div
                          class="d-flex flex-row align-items-center c-list-item--tacao"
                        >
                          <img
                            src="@/assets/img/icon/icon-rate-yellow.svg"
                            alt=""
                            class="img-responsive"
                          />
                          <h4
                            class="text-yellow-new font-weight-bold text-size-large mb-0 ml-3 d-flex align-items-center justify-content-end"
                          >
                            {{ project_on_going }}
                          </h4>
                        </div>
                        <div class="flex-row text-right">
                          <p
                            class="mb-0 text-size-mini text-uppercase font-weight-normal"
                          >
                            ON GOING
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="card p-2 mb-2 border-0 bg-green-new">
                      <div
                        class="d-flex align-items-center justify-content-between"
                      >
                        <div
                          class="d-flex flex-row align-items-center c-list-item--tacao"
                        >
                          <img
                            src="@/assets/img/icon/icon-rate-green-new.svg"
                            width="40"
                            alt=""
                            class="img-responsive"
                          />
                          <h4
                            class="text-green-new font-weight-bold text-size-large mb-0 ml-3 d-flex align-items-center justify-content-end"
                          >
                            {{ project_finish }}
                          </h4>
                        </div>
                        <div class="flex-row text-right">
                          <p
                            class="mb-0 text-size-mini text-uppercase font-weight-normal"
                          >
                            FINISH
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="grid-section-item">
            <div
              class="bg-white rounded border border-radius-style card-dashboard h-100"
            >
              <div class="card-dashboard-title px-3 py-2">
                <h4 class="mb-0">
                  Tahapan (On Going)
                </h4>
              </div>
              <div class="card-dashboard-content p-3">
                <ol class="step-indicator">
                  <li>
                    1
                  </li>
                  <li>
                    2
                  </li>
                  <li>
                    3
                  </li>
                  <li>
                    4
                  </li>
                </ol>
                <ol class="pl-3">
                  <li>
                    <div class="d-flex align-items-center mb-1">
                      <div class="flex-row w-100">
                        <div
                          class="progress-lable d-flex align-items-center justify-content-between mb-1"
                        >
                          <p
                            class="mb-0 text-capitalize text-size-normal primary-font"
                          >
                            Perencanaan
                          </p>
                          <span
                            class="badge bg-gray text-white font-weight-normal d-block p-1 pl-2 pr-2 rounded-lg"
                            >{{ tahapan_planning }}
                          </span>
                        </div>
                        <div class="progress" style="height: 6px;">
                          <div
                            class="progress-bar rounded bg-success"
                            role="progressbar"
                            :style="
                              `width:${parseInt(
                                (tahapan_planning / project_on_going) * 100
                              ) || 0}%`
                            "
                            :aria-valuenow="
                              (tahapan_planning / project_on_going) * 100 || 0
                            "
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="d-flex align-items-center mb-1">
                      <div class="flex-row w-100">
                        <div
                          class="progress-lable d-flex align-items-center justify-content-between mb-1"
                        >
                          <p
                            class="mb-0 text-capitalize text-size-normal primary-font"
                          >
                            Persiapan
                          </p>
                          <span
                            class="badge bg-gray text-white font-weight-normal d-block p-1 pl-2 pr-2 rounded-lg"
                            >{{ tahapan_preparation }}</span
                          >
                        </div>
                        <div class="progress" style="height: 6px;">
                          <div
                            class="progress-bar rounded bg-success"
                            :style="
                              `width:${parseInt(
                                (tahapan_preparation / project_on_going) * 100
                              ) || 0}%`
                            "
                            role="progressbar"
                            :aria-valuenow="tahapan_preparation"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="d-flex align-items-center mb-1">
                      <div class="flex-row w-100">
                        <div
                          class="progress-lable d-flex align-items-center justify-content-between mb-1"
                        >
                          <p
                            class="mb-0 text-capitalize text-size-normal primary-font"
                          >
                            Implementasi
                          </p>
                          <span
                            class="badge bg-gray text-white font-weight-normal d-block p-1 pl-2 pr-2 rounded-lg"
                            >{{ tahapan_implementation }}</span
                          >
                        </div>
                        <div class="progress" style="height: 6px;">
                          <div
                            class="progress-bar rounded bg-success"
                            :style="
                              `width:${parseInt(
                                (tahapan_implementation / project_on_going) *
                                  100
                              ) || 0}%`
                            "
                            role="progressbar"
                            :aria-valuenow="tahapan_implementation"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="d-flex align-items-center mb-1">
                      <div class="flex-row w-100">
                        <div
                          class="progress-lable d-flex align-items-center justify-content-between mb-1"
                        >
                          <p
                            class="mb-0 text-capitalize text-size-normal primary-font"
                          >
                            Evaluasi
                          </p>
                          <span
                            class="badge bg-gray text-white font-weight-normal d-block p-1 pl-2 pr-2 rounded-lg"
                            >{{ tahapan_evoluation }}</span
                          >
                        </div>
                        <div class="progress" style="height: 6px;">
                          <div
                            class="progress-bar rounded bg-success"
                            :style="
                              `width:${parseInt(
                                (tahapan_evoluation / project_on_going) * 100
                              ) || 0}%`
                            "
                            role="progressbar"
                            :aria-valuenow="tahapan_evoluation"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                      </div>
                    </div>
                  </li>
                </ol>
              </div>
            </div>
          </div>
          <div class="grid-section-item">
            <div
              class="bg-white rounded border border-radius-style card-dashboard"
            >
              <div class="card-dashboard-title px-3 py-2">
                <h4 class="mb-0">
                  Kategori (On Going)
                </h4>
              </div>
              <div class="card-dashboard-content p-3">
                <div class="row">
                  <div class="col-md-6">
                    <div class="chart-doughnut--wrapper">
                      <canvas
                        id="kerjasama-chart-on-going"
                        width="400"
                        height="200"
                      ></canvas>
                      <div class="chart-doughnut--text">
                        <h3
                          class="mb-1 secondary-font text-size-large font-weight-bold"
                        >
                          {{ total_kategori }}
                        </h3>
                        <h6
                          class="mb-0 secondary-font text-uppercase text-size-small font-weight-normal"
                        >
                          TOTAL ON GOING
                        </h6>
                      </div>
                    </div>
                  </div>
                  <div
                    class="col-md-6 d-flex flex-column justify-content-center"
                  >
                    <div
                      class="card p-3 mb-2 border-0 bg-yellow-new"
                      v-on:click="ubahKategori('KERJASAMA')"
                      style="cursor:pointer"
                      data-toggle="modal"
                      data-target="#modalKerjasama"
                    >
                      <div
                        class="d-flex align-items-center justify-content-between"
                      >
                        <div
                          class="d-flex flex-row align-items-center c-list-item--tacao"
                        >
                          <img
                            src="@/assets/img/icon/icon-rate-yellow.svg"
                            alt=""
                            class="img-responsive"
                          />
                          <h4
                            class="text-yellow-new font-weight-bold text-size-large mb-0 ml-3 d-flex align-items-center justify-content-end"
                          >
                            {{ kerjasama }}
                          </h4>
                        </div>
                        <div class="flex-row text-right">
                          <p
                            class="mb-0 text-size-mini text-uppercase font-weight-normal"
                          >
                            KERJASAMA
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      class="card p-3 mb-2 border-0 bg-blue-new"
                      v-on:click="ubahKategori('INVESTASI')"
                      style="cursor:pointer"
                      data-toggle="modal"
                      data-target="#modalKerjasama"
                    >
                      <div
                        class="d-flex align-items-center justify-content-between"
                      >
                        <div
                          class="d-flex flex-row align-items-center c-list-item--tacao"
                        >
                          <img
                            src="@/assets/img/icon/icon-rate-blue-new.svg"
                            alt=""
                            class="img-responsive"
                          />
                          <h4
                            class="text-blue-new font-weight-bold text-size-large mb-0 ml-3 d-flex align-items-center justify-content-end"
                          >
                            {{ investasi }}
                          </h4>
                        </div>
                        <div class="flex-row text-right">
                          <p
                            class="mb-0 text-size-mini text-uppercase font-weight-normal"
                          >
                            INVESTASI
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="grid-section-item">
            <div
              class="bg-white rounded border border-radius-style card-dashboard h-100"
            >
              <div class="card-dashboard-title px-3 py-2">
                <h4 class="mb-0">
                  Bidang (On Going)
                </h4>
              </div>
              <div class="card-dashboard-content p-3">
                <ol class="pl-3" style="list-style-type: none;">
                  <li v-for="(item, key) in listBidang" :key="key">
                    <div class="d-flex align-items-center mb-1">
                      <div class="flex-row w-100">
                        <div
                          class="progress-lable d-flex align-items-center justify-content-between mb-1"
                        >
                          <p
                            class="mb-0 text-capitalize text-size-normal primary-font"
                          >
                            {{ item.bidang }}
                          </p>
                          <span
                            class="badge bg-gray text-white font-weight-normal d-block p-1 pl-2 pr-2 rounded-lg"
                            >{{ item.jumlah }}</span
                          >
                        </div>
                        <div class="progress" style="height: 6px;">
                          <div
                            class="progress-bar rounded bg-success"
                            role="progressbar"
                            :style="
                              `width:${parseInt(
                                (item.jumlah / total_bidang) * 100
                              ) || 0}%`
                            "
                            :aria-valuenow="item.jumlah"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <div class="loadmore mt-3 d-flex justify-content-center">
                    <button
                      class="btn btn-loadmore"
                      v-on:click="limitChange()"
                      v-if="listBidang.length > 0 && limit < bidang.length"
                    >
                      LOAD MORE
                    </button>
                  </div>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="row sumber-data mt-3"
        v-if="sumber_data != null"
        v-tooltip.top-start="{
          content: `Pic : ${
            sumber_data.pic == null ? '-' : sumber_data.pic
          } <br> 
                              Kontak : ${
                                sumber_data.kontak == null
                                  ? '-'
                                  : sumber_data.kontak
                              }`,
          show: sumberTooltip,
          trigger: 'manual'
        }"
        style="cursor:pointer"
        v-on:mouseover="changeSumberTooltip"
        v-on:click="changeSumberTooltip"
      >
        <div class="col-md-12 ">
          <p class="text-size-normal ">
            &nbsp; Sumber Data : {{ sumber_data.sumber }} <br />
            &nbsp; Pembaharuan Terakhir :
            {{ sumber_data.updated_at == null ? '-' : sumber_data.updated_at }}
          </p>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      id="modalKerjasama"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modalIkpTitle"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-dialog-custom border-0 rounded-lg modal-dialog-centered"
        role="document"
      >
        <div class="modal-content">
          <div class="modal-header border-0 p-0">
            <button
              type="button"
              class="close rounded-circle bg-white"
              data-dismiss="modal"
              aria-label="Close"
            >
              <i class="fas fa-times fa-xs text-body" aria-hidden="true"></i>
            </button>
          </div>
          <div class="modal-body">
            <div class="title-w-line--right p-3">
              <h4
                class="mb-0 text-capitalize text-size-large titilium-font font-weight-normal"
              >
                Bidang (On Going)
              </h4>
            </div>
            <div class="p-4">
              <ul class="p-0 list-style-type-none c-list">
                <template v-if="kategori == 'KERJASAMA'">
                  <li v-for="(item, key) in array_kerjasama" :key="key">
                    <div class="d-flex align-items-center mb-1">
                      <div class="flex-row w-100">
                        <div
                          class="progress-lable d-flex align-items-center justify-content-between mb-1"
                        >
                          <p
                            class="mb-0 text-uppercase text-size-normal primary-font"
                          >
                            {{ item.kategori }}
                          </p>
                          <span
                            class="badge bg-gray text-white font-weight-normal d-block p-1 pl-2 pr-2 rounded-lg"
                            >{{ item.jumlah }}</span
                          >
                        </div>
                        <div class="progress" style="height: 6px;">
                          <div
                            class="progress-bar rounded bg-success"
                            role="progressbar"
                            :style="
                              'width:' +
                                (item.jumlah * 100) /
                                  sumArray(array_kerjasama) +
                                '%'
                            "
                            :aria-valuenow="item.jumlah"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                      </div>
                    </div>
                  </li>
                </template>
                <template v-if="kategori == 'INVESTASI'">
                  <li v-for="(item, key) in array_investasi" :key="key">
                    <div class="d-flex align-items-center mb-1">
                      <div class="flex-row w-100">
                        <div
                          class="progress-lable d-flex align-items-center justify-content-between mb-1"
                        >
                          <p
                            class="mb-0 text-uppercase text-size-normal primary-font"
                          >
                            {{ item.kategori }}
                          </p>
                          <span
                            class="badge bg-gray text-white font-weight-normal d-block p-1 pl-2 pr-2 rounded-lg"
                            >{{ item.jumlah }}</span
                          >
                        </div>
                        <div class="progress" style="height: 6px;">
                          <div
                            class="progress-bar rounded bg-success"
                            role="progressbar"
                            :style="
                              'width:' +
                                (item.jumlah * 100) /
                                  sumArray(array_investasi) +
                                '%'
                            "
                            :aria-valuenow="item.jumlah"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                      </div>
                    </div>
                  </li>
                </template>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
let date = new Date(window.date_now);
export default {
  metaInfo() {
    return {
      title: 'Kerjasama'
    };
  },
  data() {
    return {
      sumberTooltip: false,
      sumber_data: null,
      chartStatus: {
        datasets: [
          {
            data: [20, 10, 30, 30],
            borderWidth: 0,
            backgroundColor: ['#d5dcf2', '#F4776E', '#f5d273', '#21b030']
          }
        ],
        type: 'doughnut',
        options: {
          legend: {
            display: false
          },
          cutoutPercentage: 60,
          responsive: true,
          maintainAspectRatio: false
        }
      },
      chartKategori: {
        datasets: [
          {
            data: [20, 10],
            borderWidth: 0,
            backgroundColor: ['#FFC650', '#00A8E0']
          }
        ],
        type: 'doughnut',
        options: {
          legend: {
            display: false
          },
          cutoutPercentage: 60,
          responsive: true,
          maintainAspectRatio: false
        }
      },
      total_project: 0,
      project_on_going: 0,
      project_pending: 0,
      project_terminate: 0,
      project_finish: 0,
      tahapan_planning: 0,
      tahapan_preparation: 0,
      tahapan_implementation: 0,
      tahapan_evoluation: 0,
      kerjasama: 0,
      investasi: 0,
      array_kerjasama: [],
      array_investasi: [],
      bidang: [],
      total_bidang: 0,
      kategori: '',
      total_kategori: 0,
      array_tahun: window.date_years,
      tahun: date.getFullYear(),
      limit: 5
    };
  },
  mounted() {
    this.LoadChart();
  },

  methods: {
    changeSumberTooltip() {
      this.sumberTooltip = true;
      setTimeout(() => {
        this.sumberTooltip = false;
      }, 3000);
    },
    LoadChart() {
      window.axios
        .post(window.base_api + 'page/kerjasama', { tahun: this.tahun })
        .then(response => {
          if (response.status == 200) {
            this.sumber_data = response.data.sumber_data;
            this.chartStatus.datasets[0].data = [
              parseInt(response.data.project_pending),
              parseInt(response.data.project_terminate),
              parseInt(response.data.project_on_going),
              parseInt(response.data.project_finish)
            ];
            this.chartKategori.datasets[0].data = [
              parseInt(response.data.kerjasama),
              parseInt(response.data.investasi)
            ];
            this.setChart();

            this.project_on_going = response.data.project_on_going;
            this.project_pending = response.data.project_pending;
            this.project_terminate = response.data.project_terminate;
            this.project_finish = response.data.project_finish;

            this.total_project =
              this.project_on_going +
              this.project_pending +
              this.project_terminate +
              this.project_finish;

            this.tahapan_planning = response.data.tahapan_planning;
            this.tahapan_preparation = response.data.tahapan_preparation;
            this.tahapan_implementation = response.data.tahapan_implementation;
            this.tahapan_evoluation = response.data.tahapan_evoluation;

            this.kerjasama = response.data.kerjasama;

            this.investasi = response.data.investasi;
            this.total_kategori = response.data.total_kategori;

            this.array_kerjasama = response.data.array_kerjasama.sort(
              this.compare
            );
            this.array_investasi = response.data.array_investasi.sort(
              this.compare
            );
            this.bidang = response.data.bidang;
            if (this.bidang.length > 0) {
              this.bidang = this.bidang.sort(this.compare);
            }
            this.total_bidang = this.bidang.length;
          }
        });
    },
    ubahKategori(val) {
      this.kategori = val;
    },
    setChart() {
      var ctx = document.getElementById('kerjasama-chart').getContext('2d');
      let chartData = {
        datasets: this.chartStatus.datasets
      };

      new window.Chart(ctx, {
        type: this.chartStatus.type,
        data: chartData,
        options: this.chartStatus.options
      });

      ctx = document
        .getElementById('kerjasama-chart-on-going')
        .getContext('2d');
      chartData = {
        datasets: this.chartKategori.datasets
      };

      new window.Chart(ctx, {
        type: this.chartKategori.type,
        data: chartData,
        options: this.chartKategori.options
      });
    },
    tahunChange() {
      this.LoadChart();
      this.limit = 5;
    },
    sumArray(array) {
      array = array.map(item => {
        return item.jumlah;
      });
      return array.reduce((a, b) => a + b, 0);
    },
    compare(a, b) {
      a = a.jumlah;
      b = b.jumlah;

      let comparison = 0;
      if (a > b) {
        comparison = 1;
      } else if (a < b) {
        comparison = -1;
      }
      return comparison * -1;
    },
    limitChange() {
      this.limit += 5;
    }
  },
  computed: {
    listBidang() {
      return this.bidang.slice(0, this.limit);
    }
  }
};
</script>

<style></style>
